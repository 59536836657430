import { createSlice } from '@reduxjs/toolkit';

// biome-ignore lint/complexity/noBannedTypes: <explanation>
export type AppState = {};

export const initialState: AppState = {};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount: number) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const appSlice = createSlice({
	name: 'app',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		// increment: (state) => {
		//   // Redux Toolkit allows us to write "mutating" logic in reducers. It
		//   // doesn't actually mutate the state because it uses the Immer library,
		//   // which detects changes to a "draft state" and produces a brand new
		//   // immutable state based off those changes
		//   state.value += 1;
		// },
		// decrement: (state) => {
		//   state.value -= 1;
		// },
		// Use the PayloadAction type to declare the contents of `action.payload`
		// setActiveElectionId: (state, action: PayloadAction<number | undefined>) => {
		// 	state.electionId = action.payload;
		// },
		// setMapFilterSettings: (
		// 	state,
		// 	action: PayloadAction<{ mapFilterSettings: IMapFilterSettings; electionId: number }>,
		// ) => {
		// 	state.mapFilterSettings[action.payload.electionId] = action.payload.mapFilterSettings;
		// },
		// setSearchBarFilterControlState: (state, action: PayloadAction<boolean>) => {
		// 	state.searchBarFilterControlState = action.payload;
		// },
		// setPollingPlaces: (state, action: PayloadAction<IMapPollingPlaceGeoJSONFeatureCollection | undefined>) => {
		// 	state.pollingPlaces = action.payload;
		// },
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	// extraReducers: (builder) => {
	// 	builder
	// 		// .addCase(prepareFeaturesForMap.pending, (state) => {
	// 		// 	state.mapFeatures.status = eMapFeaturesLoadingStatus.LOADING;
	// 		// })
	// 		.addCase(prepareFeaturesForMap.fulfilled, (state, action) => {
	// 			// state.mapFeatures.status = eMapFeaturesLoadingStatus.SUCCEEDED;
	// 			// if (action.payload !== undefined) {
	// 			// 	state.mapFeatures = action.payload;
	// 			// }
	// 			state.mapFeatures = action.payload;
	// 		});
	// 	// .addCase(prepareFeaturesForMap.rejected, (state) => {
	// 	// 	state.mapFeatures.status = eMapFeaturesLoadingStatus.FAILED;
	// 	// })
	// },
});

// export const { setMapFilterSettings, setSearchBarFilterControlState, setPollingPlaces } = appSlice.actions;

// export const prepareFeaturesForMap = createAsyncThunk(
// 	'app/prepareFeaturesForMap',
// 	async (arg: { electionId: number; name: string; premises: string; state: string } /*, { getState }*/) => {
// 		// const state = getState() as RootState;

// 		// console.log('arg', arg);

// 		try {
// 			const response = await fetch(
// 				`${getAPIBaseURL()}/0.1/polling_places/lookup/?election_id=${arg.electionId}&name=${arg.name}&premises=${
// 					arg.premises
// 				}&state=${arg.state}`,
// 			);

// 			if (response.ok === true) {
// 				// setPollingPlace((await response.json()) as IPollingPlace);
// 				return (await response.json()) as IPollingPlace;
// 			} else {
// 				// setPollingPlace(null);
// 				return null;
// 			}
// 		} catch (error) {
// 			// setPollingPlace(null);
// 			return null;
// 		}
// 	},
// );

export default appSlice.reducer;
